/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { RxUtil } from "@/assets/util.js";
import { loadFormInst } from '@/assets/app.js';
import { openDialogByAlias } from '@/assets/CustomDialog.js';
import router from '@/router/index';
import '@/components/common/form';
import '@/components/common';
export default {
  name: "CustomFormEdit",
  data() {
    return {
      boKey: "",
      addAlias: "",
      alias: "",
      detailAlias: "",
      id: "",
      type: "",
      tabbarBtn: true,
      buttons: [{
        title: "启动",
        name: "handStart",
        icon: "#yd-icon-qidong",
        active: true
      }, {
        title: "保存",
        name: "handSave",
        icon: "#yd-icon-queding",
        active: true
      }, {
        title: "编辑",
        name: "handEdit",
        icon: "#yd-icon-bianji",
        active: true
      }, {
        title: "返回",
        name: "cancel",
        icon: "#yd-icon-yuyin",
        active: false
      }],
      formModels: [],
      currentViews: [],
      formVms: [],
      readonly: true
    };
  },
  mounted() {
    this.addAlias = this.$route.params.addAlias;
    this.alias = this.$route.params.alias;
    this.detailAlias = this.$route.params.detailAlias;
    this.id = this.$route.params.id;
    this.type = this.$route.params.type;
    this.readonly = this.$route.params.readonly == 'true' || this.$route.params.readonly == true ? true : false;
    var relField = this.$route.params.relField;

    //解决跳转的问题。
    this.id = this.id == "-1" ? "" : this.id;
    var realAlias = this.handleAlias();
    var url = _baseUrl + "/mobile/custom/form/" + realAlias + "/" + this.id + ".do";
    this.$dialog.loading.open('正在加载表单...');
    this.$ajax.get(url).then(response => {
      this.$dialog.loading.close();
      var rtn = response.data;
      if (!rtn.result) {
        this.errorToastBack(rtn.msg);
      }
      var canStartFlow = rtn.canStartFlow;
      var formModel = rtn.formModel;
      var models = [];
      //设置关联字段
      if (relField) {
        var jsonData = formModel.jsonData;
        var relFieldObj = JSON.parse(relField);
        for (let key in relFieldObj) {
          jsonData[key] = relFieldObj[key];
        }
      }
      models.push(formModel);
      this.formModels = models;
      //加载表单实例
      loadFormInst(this, this.readonly);
      //不启动流程
      //			if(!canStartFlow){
      //				this.buttons.splice(0,1);
      //			}
      //			//添加自定义按钮。			
      //			this.constructButton();
    }).catch(error => {
      this.$dialog.loading.close();
      console.log(error);
    });
  },
  methods: {
    getTitle() {
      if (this.type == 'add') {
        return '单据录入';
      } else if (this.type == 'edit') {
        return '单据编辑';
      } else if (this.type == 'detail') {
        return '单据明细';
      }
    },
    calcBtn(name) {
      if (name == 'handSave' && this.readonly) {
        return false;
      } else if (name == 'handEdit' && (this.type == 'edit' || this.type == 'add')) {
        return false;
      }
      return true;
    },
    handleAlias() {
      if (this.type == 'add') {
        return this.addAlias;
      } else if (this.type == 'detail') {
        return this.detailAlias;
      } else if (this.type == 'edit') {
        return this.alias;
      }
    },
    //调用表单自定义方法。
    handclick(click) {
      this["func_" + click](this.formVms[0]);
    },
    handOk(action) {
      var alias = this.handleAlias();
      var setting = {
        alias: alias,
        action: action
      };
      var formData = JSON.stringify(this.formVms[0].data);
      var aryVm = this.formVms;
      for (var i = 0; i < aryVm.length; i++) {
        var vm = aryVm[i];
        var rtn = vm.valid(true);
        if (!rtn.valid) {
          this.errorToast(rtn.msg, 2000);
          return;
        }
        let bodefid = vm.boDefId;
        if (window['selfValidate_' + bodefid]) {
          var tmp = window['selfValidate_' + bodefid](vm, true);
          if (tmp && !tmp.valid) {
            this.errorToast(tmp.msg, 2000);
            return;
          }
        }
      }
      var data = {
        formData: formData,
        setting: setting
      };
      this.$dialog.loading.open('正在保存...');
      var url = _baseUrl + "/sys/customform/sysCustomFormSetting/saveData.do";
      this.$ajaxJson.post(url, data).then(res => {
        this.$dialog.loading.close();
        var rtn = res.data;
        if (rtn.success) {
          this.goBack();
        } else {
          this.errorToast(rtn.message);
        }
      }).catch(error => {
        this.$dialog.loading.close();
        console.log(error);
      });
    },
    func_handEdit() {
      router.push({
        name: "customForm",
        params: {
          alias: this.alias,
          addAlias: this.addAlias,
          detailAlias: this.detailAlias,
          id: this.id,
          readonly: false,
          type: 'edit',
          relField: 0
        }
      });
    },
    func_handSave() {
      this.handOk("save");
    },
    func_handStart() {
      this.handOk("startFlow");
    },
    func_cancel() {
      this.goBack();
    },
    constructButton() {
      /*buttonFuntions 格式:[{
      	{
        			title:"确定",
        			name:"handSave",
        			icon:"yd-icon-save",
        			active:true,
        			action:function(data){
        				
        			}
        		}
      }]
      var buttonFuntions=[...];
      */
      if (window.buttonFuntions) {
        for (var i = 0; i < buttonFuntions.length; i++) {
          var json = buttonFuntions[i];
          this["func_" + json.name] = json.action;
          delete json.action;
          this.buttons.push(json);
        }
      }
      //给vue 实例增加方法。
      /*custFuntions 格式:[{
      	{
        			name:"handSave",
        			action:function(vueInst){
        			}
        		}
      }]
      var custFuntions=[...];
      */
      if (window.custFuntions) {
        for (var i = 0; i < custFuntions.length; i++) {
          var json = custFuntions[i];
          this["func_" + json.name] = json.action;
        }
      }
    }
  }
};